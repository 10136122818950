import { withDevtools, withStorageSync } from '@angular-architects/ngrx-toolkit';
import { HttpContext } from '@angular/common/http';
import { inject } from '@angular/core';
import { signalStore, withState, withComputed, withMethods, patchState } from '@ngrx/signals';
import { Store } from '@ngrx/store';
import { GetStateData, IStateData } from 'src/app/shared/interfaces/state/state';
import {
  FixedAssetAccountingSchemeDto,
  FixedAssetCategoryDto,
  FixedAssetGroupDto,
  HttpClientFixedAssetAccountingScheme,
  HttpClientFixedAssetCategory,
  HttpClientFixedAssetGroup,
  HttpClientGlAccount,
  HttpClientItemAccountingScheme,
  HttpClientItemCategory,
  HttpClientItemGroup,
  HttpClientLocation,
  HttpClientUnitOfMeasure,
  ItemCategoryDto,
  ItemGroupDto,
  KeyValuePairOfIntegerAndString,
  KeyValuePairOfLongAndString,
  LocationDto,
  UnitOfMeasureDto,
} from 'src/app/shared/nswag.api';
import { selectSkipCache } from '../inventory/store/inventory.selectors';
import moment from 'moment';
import { EXPIRE_API_SECONDS } from '../shared/constants/api-priority.seconds';
import { firstValueFrom, take } from 'rxjs';
import { nswagCatchOperator } from '../shared/operators/nswag-catch-operator';

type INVENTORY_STATE = {
  cachedLocations: IStateData<LocationDto>[];
  cachedSchemes: IStateData<KeyValuePairOfIntegerAndString[]>;
  cachedGroups: IStateData<ItemGroupDto>[];
  cachedAssetsGroups: IStateData<FixedAssetGroupDto>[];
  cachedCategories: IStateData<ItemCategoryDto>[];
  cachedGlSchemes: IStateData<FixedAssetAccountingSchemeDto>[];
  cachedAssetCategories: IStateData<FixedAssetCategoryDto>[];
  cachedUnits: IStateData<UnitOfMeasureDto>[];
  cachedParentCategory: IStateData<FixedAssetCategoryDto>[];
  cachedGlAccs: IStateData<KeyValuePairOfLongAndString[]>;
  areAllSchemesLoaded: boolean | undefined;
  areAllGlAccsLoaded: boolean | undefined;
};

const INITIAL_INVENTORY_STATE: INVENTORY_STATE = {
  cachedLocations: [],
  cachedSchemes: { data: undefined, lastUpdatedDate: undefined, loading: false },
  cachedUnits: [],
  cachedGlAccs: { data: undefined, lastUpdatedDate: undefined, loading: false },
  cachedParentCategory: [],
  cachedGlSchemes: [],
  cachedAssetCategories: [],
  cachedGroups: [],
  cachedCategories: [],
  cachedAssetsGroups: [],
  areAllSchemesLoaded: false,
  areAllGlAccsLoaded: false,
};

export const InventoryStore = signalStore(
  { providedIn: 'root' },
  withState(INITIAL_INVENTORY_STATE),
  withComputed(() => ({})),

  withMethods(
    (
      store,
      httpClientLocation = inject(HttpClientLocation),
      httpClientItemAccountingScheme = inject(HttpClientItemAccountingScheme),
      httpClientUnitOfMeasure = inject(HttpClientUnitOfMeasure),
      httpClientGlAccount = inject(HttpClientGlAccount),
      httpClientFixedAssetCategory = inject(HttpClientFixedAssetCategory),
      httpClientItemGroup = inject(HttpClientItemGroup),
      httpClientItemCategory = inject(HttpClientItemCategory),
      httpClientFixedAssetGroup = inject(HttpClientFixedAssetGroup),
      httpClientFixedAssetAccountingScheme = inject(HttpClientFixedAssetAccountingScheme),
      oldStore = inject(Store),
    ) => ({
      getLocationById: async (force_skip, locationNo: string, httpContext?: HttpContext) => {
        if (!force_skip) {
          const skip_cache = oldStore.selectSignal(selectSkipCache(moment()));
          const location = store.cachedLocations().find(value => value.data?.no === locationNo);
          const existingData = GetStateData(location, moment(), EXPIRE_API_SECONDS.LOCATIONS, skip_cache());
          if (existingData) return;
        }

        const itemsOnState = [...(store.cachedLocations?.() || [])];

        const idx = itemsOnState.findIndex(x => x.data?.no === locationNo);
        if (idx > -1) {
          itemsOnState[idx].loading = true;
        } else {
          //add new item on state with loading true
          itemsOnState.push({
            data: new LocationDto({ no: locationNo }),
            lastUpdatedDate: undefined,
            loading: true,
          });
        }
        patchState(store, {
          cachedLocations: itemsOnState,
        });
        const newData = await firstValueFrom(httpClientLocation.getLocationById(locationNo, httpContext).pipe(nswagCatchOperator(), take(1)));
        if (!newData.succeeded || !newData.data) {
          let removedNotFoundedElement = [...store.cachedLocations().filter(el => el.data?.no !== locationNo)];
          patchState(store, {
            cachedLocations: removedNotFoundedElement,
          });
          return;
        }
        const indexOfLocationNo = itemsOnState.findIndex(x => x.data?.no === locationNo);
        if (indexOfLocationNo > -1) {
          itemsOnState[indexOfLocationNo].data = newData.data;
          itemsOnState[indexOfLocationNo].loading = false;
          itemsOnState[indexOfLocationNo].lastUpdatedDate = moment();
          patchState(store, {
            cachedLocations: [...itemsOnState],
          });
        } else {
          patchState(store, {
            cachedLocations: [
              ...store.cachedLocations().filter(el => el.data?.no !== locationNo),
              ...[
                {
                  data: newData.data,
                  lastUpdatedDate: moment(),
                  loading: false,
                },
              ],
            ],
          });
        }
      },
      getSchemeById: async (force_skip, httpContext?: HttpContext) => {
        if (!force_skip) {
          const skip_cache = oldStore.selectSignal(selectSkipCache(moment()));
          const schemes = store.cachedSchemes();
          const existingData = GetStateData(schemes, moment(), EXPIRE_API_SECONDS.ITEM_SCHEMES, skip_cache());
          if (existingData || store.areAllSchemesLoaded()) return;
        }

        patchState(store, {
          areAllSchemesLoaded: true,
        });

        const newData = await firstValueFrom(httpClientItemAccountingScheme.getAllIdName(httpContext).pipe(nswagCatchOperator(), take(1)));
        if (!newData.succeeded || !newData.data) return;

        const dataFromApi = {
          data: newData.data,
          lastUpdatedDate: moment(),
          loading: false,
        };

        patchState(store, {
          cachedSchemes: dataFromApi,
          areAllSchemesLoaded: false,
        });
      },
      getUnitById: async (force_skip, unitCode: string, httpContext?: HttpContext) => {
        if (!force_skip) {
          const skip_cache = oldStore.selectSignal(selectSkipCache(moment()));
          const unit = store.cachedUnits().find(value => value.data?.code === unitCode);
          const existingData = GetStateData(unit, moment(), EXPIRE_API_SECONDS.UNIT_OF_MEASURES, skip_cache());
          if (existingData) return;
        }

        const itemsOnState = [...(store.cachedUnits?.() || [])];

        const idx = itemsOnState.findIndex(x => x.data?.code === unitCode);
        if (idx > -1) {
          itemsOnState[idx].loading = true;
        } else {
          //add new item on state with loading true
          itemsOnState.push({
            data: new UnitOfMeasureDto({ code: unitCode }),
            lastUpdatedDate: undefined,
            loading: true,
          });
        }
        patchState(store, {
          cachedUnits: itemsOnState,
        });
        const newData = await firstValueFrom(httpClientUnitOfMeasure.getUnitOfMeasureById(unitCode, httpContext).pipe(nswagCatchOperator(), take(1)));
        if (!newData.succeeded || !newData.data) {
          let removedNotFoundedElement = [...store.cachedUnits().filter(el => el.data?.code !== unitCode)];
          patchState(store, {
            cachedUnits: removedNotFoundedElement,
          });
          return;
        }
        const indexOfUnitCode = itemsOnState.findIndex(x => x.data?.code === unitCode);
        if (indexOfUnitCode > -1) {
          itemsOnState[indexOfUnitCode].data = newData.data;
          itemsOnState[indexOfUnitCode].loading = false;
          itemsOnState[indexOfUnitCode].lastUpdatedDate = moment();
          patchState(store, {
            cachedUnits: [...itemsOnState],
          });
        } else {
          patchState(store, {
            cachedUnits: [
              ...store.cachedUnits().filter(el => el.data?.code !== unitCode),
              ...[
                {
                  data: newData.data,
                  lastUpdatedDate: moment(),
                  loading: false,
                },
              ],
            ],
          });
        }
      },
      getGlAccById: async (force_skip, httpContext?: HttpContext) => {
        if (!force_skip) {
          const skip_cache = oldStore.selectSignal(selectSkipCache(moment()));
          const glAccs = store.cachedGlAccs();
          const existingData = GetStateData(glAccs, moment(), EXPIRE_API_SECONDS.GL_ACCOUNTS, skip_cache());
          if (existingData || store.areAllGlAccsLoaded()) return;
        }

        patchState(store, {
          areAllGlAccsLoaded: true,
        });

        const newData = await firstValueFrom(
          httpClientGlAccount.getAllGlAccountsIdDescription(true, httpContext).pipe(nswagCatchOperator(), take(1)),
        );
        if (!newData.succeeded || !newData.data) return;

        const dataFromApi = {
          data: newData.data,
          lastUpdatedDate: moment(),
          loading: false,
        };

        patchState(store, {
          cachedGlAccs: dataFromApi,
          areAllGlAccsLoaded: false,
        });
      },
      getParentCategoryById: async (force_skip, parentId: number, httpContext?: HttpContext) => {
        if (!force_skip) {
          const skip_cache = oldStore.selectSignal(selectSkipCache(moment()));
          const parentCategory = store.cachedParentCategory().find(value => value.data?.id === parentId);
          const existingData = GetStateData(parentCategory, moment(), EXPIRE_API_SECONDS.PARENTCATEGORY, skip_cache());
          if (existingData) return;
        }

        const itemsOnState = [...(store.cachedParentCategory?.() || [])];

        const idx = itemsOnState.findIndex(x => x.data?.id === parentId);
        if (idx > -1) {
          itemsOnState[idx].loading = true;
        } else {
          //add new item on state with loading true
          itemsOnState.push({
            data: new FixedAssetCategoryDto({ id: parentId }),
            lastUpdatedDate: undefined,
            loading: true,
          });
        }
        patchState(store, {
          cachedParentCategory: itemsOnState,
        });
        const newData = await firstValueFrom(
          httpClientFixedAssetCategory.getFixedAssetCategoryById(parentId, httpContext).pipe(nswagCatchOperator(), take(1)),
        );
        if (!newData.succeeded || !newData.data) {
          let removedNotFoundedElement = [...store.cachedParentCategory().filter(el => el.data?.id !== parentId)];
          patchState(store, {
            cachedParentCategory: removedNotFoundedElement,
          });
          return;
        }
        const indexOfParentCategoryId = itemsOnState.findIndex(x => x.data?.id === parentId);
        if (indexOfParentCategoryId > -1) {
          itemsOnState[indexOfParentCategoryId].data = newData.data;
          itemsOnState[indexOfParentCategoryId].loading = false;
          itemsOnState[indexOfParentCategoryId].lastUpdatedDate = moment();
          patchState(store, {
            cachedParentCategory: [...itemsOnState],
          });
        } else {
          patchState(store, {
            cachedParentCategory: [
              ...store.cachedParentCategory().filter(el => el.data?.id !== parentId),
              ...[
                {
                  data: newData.data,
                  lastUpdatedDate: moment(),
                  loading: false,
                },
              ],
            ],
          });
        }
      },
      getGlAssetSchemesById: async (force_skip, schemeId: number, httpContext?: HttpContext) => {
        if (!force_skip) {
          const skip_cache = oldStore.selectSignal(selectSkipCache(moment()));
          const glAccountScheme = store.cachedGlSchemes().find(value => value.data?.id === schemeId);
          const existingData = GetStateData(glAccountScheme, moment(), EXPIRE_API_SECONDS.ITEM_SCHEMES, skip_cache());
          if (existingData) return;
        }

        const itemsOnState = [...(store.cachedGlSchemes?.() || [])];

        const idx = itemsOnState.findIndex(x => x.data?.id === schemeId);
        if (idx > -1) {
          itemsOnState[idx].loading = true;
        } else {
          //add new item on state with loading true
          itemsOnState.push({
            data: new FixedAssetCategoryDto({ id: schemeId }),
            lastUpdatedDate: undefined,
            loading: true,
          });
        }
        patchState(store, {
          cachedGlSchemes: itemsOnState,
        });
        const newData = await firstValueFrom(httpClientFixedAssetAccountingScheme.getById(schemeId, httpContext).pipe(nswagCatchOperator(), take(1)));
        if (!newData.succeeded || !newData.data) {
          let removedNotFoundedElement = [...store.cachedGlSchemes().filter(el => el.data?.id !== schemeId)];
          patchState(store, {
            cachedGlSchemes: removedNotFoundedElement,
          });
          return;
        }
        const indexOfAssetAccountingSchemeId = itemsOnState.findIndex(x => x.data?.id === schemeId);
        if (indexOfAssetAccountingSchemeId > -1) {
          itemsOnState[indexOfAssetAccountingSchemeId].data = newData.data;
          itemsOnState[indexOfAssetAccountingSchemeId].loading = false;
          itemsOnState[indexOfAssetAccountingSchemeId].lastUpdatedDate = moment();
          patchState(store, {
            cachedGlSchemes: [...itemsOnState],
          });
        } else {
          patchState(store, {
            cachedGlSchemes: [
              ...store.cachedGlSchemes().filter(el => el.data?.id !== schemeId),
              ...[
                {
                  data: newData.data,
                  lastUpdatedDate: moment(),
                  loading: false,
                },
              ],
            ],
          });
        }
      },
      getAssetCategoryById: async (force_skip, assetCategoryId: number, httpContext?: HttpContext) => {
        if (!force_skip) {
          const skip_cache = oldStore.selectSignal(selectSkipCache(moment()));
          const glAccountScheme = store.cachedAssetCategories().find(value => value.data?.id === assetCategoryId);
          const existingData = GetStateData(glAccountScheme, moment(), EXPIRE_API_SECONDS.ITEM_CATEGORY, skip_cache());
          if (existingData) return;
        }

        const itemsOnState = [...(store.cachedAssetCategories?.() || [])];

        const idx = itemsOnState.findIndex(x => x.data?.id === assetCategoryId);
        if (idx > -1) {
          itemsOnState[idx].loading = true;
        } else {
          //add new item on state with loading true
          itemsOnState.push({
            data: new FixedAssetCategoryDto({ id: assetCategoryId }),
            lastUpdatedDate: undefined,
            loading: true,
          });
        }
        patchState(store, {
          cachedAssetCategories: itemsOnState,
        });
        const newData = await firstValueFrom(
          httpClientFixedAssetCategory.getFixedAssetCategoryById(assetCategoryId, httpContext).pipe(nswagCatchOperator(), take(1)),
        );
        if (!newData.succeeded || !newData.data) {
          let removedNotFoundedElement = [...store.cachedAssetCategories().filter(el => el.data?.id !== assetCategoryId)];
          patchState(store, {
            cachedAssetCategories: removedNotFoundedElement,
          });
          return;
        }
        const indexOfAssetCategoryId = itemsOnState.findIndex(x => x.data?.id === assetCategoryId);
        if (indexOfAssetCategoryId > -1) {
          itemsOnState[indexOfAssetCategoryId].data = newData.data;
          itemsOnState[indexOfAssetCategoryId].loading = false;
          itemsOnState[indexOfAssetCategoryId].lastUpdatedDate = moment();
          patchState(store, {
            cachedAssetCategories: [...itemsOnState],
          });
        } else {
          patchState(store, {
            cachedAssetCategories: [
              ...store.cachedAssetCategories().filter(el => el.data?.id !== assetCategoryId),
              ...[
                {
                  data: newData.data,
                  lastUpdatedDate: moment(),
                  loading: false,
                },
              ],
            ],
          });
        }
      },
      getItemGroupById: async (force_skip, groupId: number, httpContext?: HttpContext) => {
        if (!force_skip) {
          const skip_cache = oldStore.selectSignal(selectSkipCache(moment()));
          const itemGroup = store.cachedGroups().find(value => value.data?.id === groupId);
          const existingData = GetStateData(itemGroup, moment(), EXPIRE_API_SECONDS.ITEM_GROUP, skip_cache());
          if (existingData) return;
        }

        const itemsOnState = [...(store.cachedGroups?.() || [])];

        const idx = itemsOnState.findIndex(x => x.data?.id === groupId);
        if (idx > -1) {
          itemsOnState[idx].loading = true;
        } else {
          //add new item on state with loading true
          itemsOnState.push({
            data: new ItemGroupDto({ id: groupId }),
            lastUpdatedDate: undefined,
            loading: true,
          });
        }
        patchState(store, {
          cachedGroups: itemsOnState,
        });
        const newData = await firstValueFrom(httpClientItemGroup.getItemGroupById(groupId, httpContext).pipe(nswagCatchOperator(), take(1)));
        if (!newData.succeeded || !newData.data) {
          let removedNotFoundedElement = [...store.cachedGroups().filter(el => el.data?.id !== groupId)];
          patchState(store, {
            cachedGroups: removedNotFoundedElement,
          });
          return;
        }
        const indexOfItemGroupId = itemsOnState.findIndex(x => x.data?.id === groupId);
        if (indexOfItemGroupId > -1) {
          itemsOnState[indexOfItemGroupId].data = newData.data;
          itemsOnState[indexOfItemGroupId].loading = false;
          itemsOnState[indexOfItemGroupId].lastUpdatedDate = moment();
          patchState(store, {
            cachedGroups: [...itemsOnState],
          });
        } else {
          patchState(store, {
            cachedGroups: [
              ...store.cachedGroups().filter(el => el.data?.id !== groupId),
              ...[
                {
                  data: newData.data,
                  lastUpdatedDate: moment(),
                  loading: false,
                },
              ],
            ],
          });
        }
      },
      getItemCategoryById: async (force_skip, categoryId: number, httpContext?: HttpContext) => {
        if (!force_skip) {
          const skip_cache = oldStore.selectSignal(selectSkipCache(moment()));
          const itemCategory = store.cachedCategories().find(value => value.data?.id === categoryId);
          const existingData = GetStateData(itemCategory, moment(), EXPIRE_API_SECONDS.ITEM_CATEGORY, skip_cache());
          if (existingData) return;
        }

        const itemsOnState = [...(store.cachedCategories?.() || [])];

        const idx = itemsOnState.findIndex(x => x.data?.id === categoryId);
        if (idx > -1) {
          itemsOnState[idx].loading = true;
        } else {
          //add new item on state with loading true
          itemsOnState.push({
            data: new ItemCategoryDto({ id: categoryId }),
            lastUpdatedDate: undefined,
            loading: true,
          });
        }
        patchState(store, {
          cachedCategories: itemsOnState,
        });
        const newData = await firstValueFrom(httpClientItemCategory.getItemCategoryById(categoryId, httpContext).pipe(nswagCatchOperator(), take(1)));
        if (!newData.succeeded || !newData.data) {
          let removedNotFoundedElement = [...store.cachedCategories().filter(el => el.data?.id !== categoryId)];
          patchState(store, {
            cachedCategories: removedNotFoundedElement,
          });
          return;
        }
        const indexOfCategoryId = itemsOnState.findIndex(x => x.data?.id === categoryId);
        if (indexOfCategoryId > -1) {
          itemsOnState[indexOfCategoryId].data = newData.data;
          itemsOnState[indexOfCategoryId].loading = false;
          itemsOnState[indexOfCategoryId].lastUpdatedDate = moment();
          patchState(store, {
            cachedCategories: [...itemsOnState],
          });
        } else {
          patchState(store, {
            cachedCategories: [
              ...store.cachedCategories().filter(el => el.data?.id !== categoryId),
              ...[
                {
                  data: newData.data,
                  lastUpdatedDate: moment(),
                  loading: false,
                },
              ],
            ],
          });
        }
      },
      getAssetGroupById: async (force_skip, assetGroupId: number, httpContext?: HttpContext) => {
        if (!force_skip) {
          const skip_cache = oldStore.selectSignal(selectSkipCache(moment()));
          const assetGroup = store.cachedAssetsGroups().find(value => value.data?.id === assetGroupId);
          const existingData = GetStateData(assetGroup, moment(), EXPIRE_API_SECONDS.ITEM_GROUP, skip_cache());
          if (existingData) return;
        }

        const itemsOnState = [...(store.cachedAssetsGroups?.() || [])];

        const idx = itemsOnState.findIndex(x => x.data?.id === assetGroupId);
        if (idx > -1) {
          itemsOnState[idx].loading = true;
        } else {
          //add new item on state with loading true
          itemsOnState.push({
            data: new FixedAssetGroupDto({ id: assetGroupId }),
            lastUpdatedDate: undefined,
            loading: true,
          });
        }
        patchState(store, {
          cachedAssetsGroups: itemsOnState,
        });
        const newData = await firstValueFrom(
          httpClientFixedAssetGroup.getFixedAssetGroupById(assetGroupId, httpContext).pipe(nswagCatchOperator(), take(1)),
        );
        if (!newData.succeeded || !newData.data) {
          let removedNotFoundedElement = [...store.cachedAssetsGroups().filter(el => el.data?.id !== assetGroupId)];
          patchState(store, {
            cachedAssetsGroups: removedNotFoundedElement,
          });
          return;
        }
        const indexOfAssetGroupId = itemsOnState.findIndex(x => x.data?.id === assetGroupId);
        if (indexOfAssetGroupId > -1) {
          itemsOnState[indexOfAssetGroupId].data = newData.data;
          itemsOnState[indexOfAssetGroupId].loading = false;
          itemsOnState[indexOfAssetGroupId].lastUpdatedDate = moment();
          patchState(store, {
            cachedAssetsGroups: [...itemsOnState],
          });
        } else {
          patchState(store, {
            cachedAssetsGroups: [
              ...store.cachedAssetsGroups().filter(el => el.data?.id !== assetGroupId),
              ...[
                {
                  data: newData.data,
                  lastUpdatedDate: moment(),
                  loading: false,
                },
              ],
            ],
          });
        }
      },
      clearInventoryCache: () => {
        patchState(store, INITIAL_INVENTORY_STATE);
      },

      clearGlAccountsCache: () => {
        patchState(store, {
          cachedGlSchemes: [],
        });
      },
    }),
  ),
  withStorageSync({
    key: 'INVENTORY',
    autoSync: true,
  }),
  withDevtools('INVENTORY'),
);
