import { patchState, signalStore, withComputed, withHooks, withMethods, withState } from '@ngrx/signals';
import { GetStateData, IStateData } from '../shared/interfaces/state/state';
import { HttpClientUserOption, UserCurrencyOptionEnum, UserOptionEntity, UserOptionEnum } from '../shared/signalr.api';
import { EXPIRE_API_SECONDS } from '../shared/constants/api-priority.seconds';
import { computed, inject } from '@angular/core';
import moment from 'moment';
import { Store } from '@ngrx/store';
import { withDevtools, withStorageSync } from '@angular-architects/ngrx-toolkit';
import { firstValueFrom, take } from 'rxjs';
import { nswagCatchOperator } from '../shared/operators/nswag-catch-operator';
import { selectSkipCache } from '../inventory/store/inventory.selectors';

type USER_OPTIONS_STATE = {
  userOptions: IStateData<UserOptionEntity[]>;
  loading: boolean;
  error: string | undefined;
};

const INITIAL_USER_OPTIONS_STATE: USER_OPTIONS_STATE = {
  userOptions: {
    data: undefined,
    lastUpdatedDate: undefined,
  },
  loading: false,
  error: undefined,
};

export const UserOptionStore = signalStore(
  { providedIn: 'root' },
  withState(INITIAL_USER_OPTIONS_STATE),
  withComputed(state => ({
    hasDataInStore: computed(() => !!state.userOptions() && !!state.userOptions()),
    isLoading: computed(() => state.loading() === true),
    hasError: computed(() => !!state.error()),
    showDropdownContent: computed(() => state.userOptions()?.data?.find(x => x.name === UserOptionEnum.ShowDropdownContent)?.value == 'true'),
    itemCurrencyConversion: computed(() => {
      const value = state.userOptions()?.data?.find(x => x.name === UserOptionEnum.ItemCurrencyConversion)?.value;
      if (!value) return UserCurrencyOptionEnum.None;

      return UserCurrencyOptionEnum[value];
    }),
  })),
  withMethods((store, httpClientUserOption = inject(HttpClientUserOption), oldStore = inject(Store)) => ({
    loadUserOptions: async (force_skip: boolean = false) => {
      patchState(store, { loading: true, error: undefined });

      if (!force_skip) {
        const skip_cache = oldStore.selectSignal(selectSkipCache(moment()));
        const uOpts = store.userOptions();
        const existingData = GetStateData(uOpts, moment(), EXPIRE_API_SECONDS.USER_OPTIONS, skip_cache());
        if (existingData) return;
      }

      const newData = await firstValueFrom(httpClientUserOption.getValuesOrDefault().pipe(nswagCatchOperator(), take(1)));
      if (!newData.succeeded || !newData.data || newData.data.length === 0) {
        patchState(store, {
          loading: false,
          userOptions: INITIAL_USER_OPTIONS_STATE.userOptions,
          error: newData.message,
        });
        return;
      }

      patchState(store, {
        loading: false,
        error: undefined,
        userOptions: { data: newData.data, lastUpdatedDate: moment() },
      });
    },
    clearUserOptions: () => {
      patchState(store, INITIAL_USER_OPTIONS_STATE);
    },
    userOptionByName: (name: UserOptionEnum) => {
      return store.userOptions()?.data?.find(x => x.name === name)?.value;
    },
    saveChange: async (optionName: UserOptionEnum, value: string) => {
      patchState(store, { loading: true, error: undefined });

      const res = await firstValueFrom(httpClientUserOption.save(optionName, value).pipe(nswagCatchOperator(), take(1)));

      if (!res.succeeded || !res.data) {
        patchState(store, { loading: false, error: res.message });
        return;
      }

      const oldUserOptionIndex = store.userOptions()?.data?.findIndex(x => x.name === optionName);
      if (oldUserOptionIndex == undefined || oldUserOptionIndex == -1) {
        patchState(store, {
          loading: false,
          error: 'User option not found',
        });
        return;
      }

      const newUserOptions = store.userOptions().data ?? [];
      newUserOptions[oldUserOptionIndex] = res.data;
      patchState(store, {
        loading: false,
        error: undefined,
        userOptions: {
          ...store.userOptions(),
          data: newUserOptions,
        },
      });
    },
  })),
  withHooks({
    onInit: () => {
      console.log('UserOptionStore initialized');
    },
    onDestroy: () => {
      console.log('UserOptionStore destroyed');
    },
  }),
  withStorageSync({
    key: 'userOptions',
    autoSync: true,
  }),
  withDevtools('UserOptionStore'),
);
