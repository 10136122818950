// noinspection JSUnusedGlobalSymbols

import { filter, map, of, switchMap, take, tap } from 'rxjs';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  CcGetAllOrFilteredRequest,
  DynamicPage,
  HttpClientCompanyStructure,
  HttpClientCostingCenter,
  HttpClientCurrencies,
  Request2,
} from '../../shared/nswag.api';

import { nswagCatchOperator } from '../../shared/operators/nswag-catch-operator';
import {
  activeCurrenciesFailure,
  activeCurrenciesRequest,
  activeCurrenciesSuccess,
  skipCacheRequest,
  activecompanyStructuresFailure,
  activeCompanyStructuresSuccess,
  activeCompanyStructuresRequest,
  activeCostingCentersRequest,
  activeCostingCentersSuccess,
  activeCostingCentersFailure,
} from './configurations.actions';
import { TranslateService } from '@ngx-translate/core';
import { EXPIRE_API_SECONDS } from '../../shared/constants/api-priority.seconds';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { SharedService } from 'src/app/shared/services/shared.service';

@Injectable()
export class ConfigurationsEffects {
  skipCacheChange$ = createEffect(
    () =>
      this._actions.pipe(
        ofType(skipCacheRequest),
        filter(value => value.skipCache),
        switchMap(data =>
          data.showToaster
            ? this.translate.get('general.skip_cache_enabled', {
                interval: EXPIRE_API_SECONDS.SKIP_CACHE / 60,
                interval_name: 'min',
              })
            : of(false).pipe(take(1)),
        ),
        take(1),
        filter(message => !!message),
        tap(message => this.toast.info(message)),
        tap(() => {
          this.sharedService.clearCacheFunction();
          // this.store.dispatch(ClearItemsCache());
          // this.store.dispatch(ClearInventoryCache());
          // this.store.dispatch(ClearUsersCache());
          // this.store.dispatch(ClearConfigurationCache());
          // // todo pastrimi i userOptionsStore & configurationsStore
          // this.userOptionsStore.clearUserOptions();
          // this.configurationsStore.clearConfigurations();
        }),
      ),
    { dispatch: false },
  );

  getActiveCurrencies$ = createEffect(() => {
    try {
      return this._actions.pipe(
        ofType(activeCurrenciesRequest),
        switchMap(() =>
          this.httpClientCurrencies.activeCurrencies(new Request2()).pipe(
            nswagCatchOperator(),
            take(1),
            map(response =>
              response.succeeded && !!response.data
                ? activeCurrenciesSuccess({
                    activeCurrencies: response.data,
                  })
                : activeCurrenciesFailure({
                    error: response.message ?? 'Unknown error',
                  }),
            ),
          ),
        ),
      );
    } catch {
      return of(activeCurrenciesFailure({ error: 'Unknown error' }));
    }
  });

  logsGetAllOrFilteredRequest: CcGetAllOrFilteredRequest = new CcGetAllOrFilteredRequest({
    page: new DynamicPage({
      number: 1,
      size: 9999,
    }),
  });
  getActiveCostingCenters$ = createEffect(() => {
    try {
      return this._actions.pipe(
        ofType(activeCostingCentersRequest),
        switchMap(() =>
          this.httpClientCostingCenter.getAllOrFilteredCostingCenter(this.logsGetAllOrFilteredRequest).pipe(
            nswagCatchOperator(),
            take(1),
            map(response => {
              if (response.succeeded && !!response.data) {
                return activeCostingCentersSuccess({
                  costingCenter: response.data?.results,
                });
              } else {
                return activeCostingCentersFailure({
                  error: response.message ?? 'Unknown error',
                });
              }
            }),
          ),
        ),
      );
    } catch {
      return of(activeCostingCentersFailure({ error: 'Unknown error' }));
    }
  });

  getActiveCompanyStructures$ = createEffect(() => {
    try {
      return this._actions.pipe(
        ofType(activeCompanyStructuresRequest),
        switchMap(() =>
          this.httpClientCompanyStructure.companyStructureGetAll().pipe(
            nswagCatchOperator(),
            take(1),
            map(response => {
              if (response.succeeded && !!response.data) {
                return activeCompanyStructuresSuccess({
                  companyStructure: response.data,
                });
              } else {
                return activecompanyStructuresFailure({
                  error: response.message ?? 'Unknown error',
                });
              }
            }),
          ),
        ),
      );
    } catch {
      return of(activecompanyStructuresFailure({ error: 'Unknown error' }));
    }
  });

  constructor(
    private _actions: Actions,
    private httpClientCostingCenter: HttpClientCostingCenter,
    private httpClientCompanyStructure: HttpClientCompanyStructure,
    private httpClientCurrencies: HttpClientCurrencies,
    private toast: ToastrService,
    private translate: TranslateService,
    private store: Store,
    private sharedService: SharedService,
    // private storeService: StoreService,
  ) {}
}
