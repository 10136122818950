import { patchState, signalStore, withComputed, withMethods, withState } from '@ngrx/signals';
import { HttpClientHumanResources, HttpClientPaymentType, InsuranceTaxDto, PaymentTypeDto, PaymentTypeGetAll } from '../shared/nswag.api';
import { inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { firstValueFrom, take } from 'rxjs';
import { nswagCatchOperator } from '../shared/operators/nswag-catch-operator';
import { withDevtools } from '@angular-architects/ngrx-toolkit';

type HR_STATE = {
  //   paymentTypes?: PaymentTypeDto[];
  insuraceTaxes?: InsuranceTaxDto;
  paymentTypes?: PaymentTypeDto[];
};

const INITIAL_HR_STATE: HR_STATE = {
  paymentTypes: undefined,
  insuraceTaxes: undefined,
};

export const HrStore = signalStore(
  { providedIn: 'root' },
  withState(INITIAL_HR_STATE),
  withComputed(() => ({})),
  withMethods(
    (
      store,
      httpClientPaymentType = inject(HttpClientPaymentType),
      httpClientHumanResources = inject(HttpClientHumanResources),
      oldStore = inject(Store),
    ) => ({
      getPaymentTypes: async (force_skip = false) => {
        const data = store.paymentTypes!();
        if (data) return;
        const newData = await firstValueFrom(httpClientPaymentType.readPaymentTypes(new PaymentTypeGetAll()).pipe(nswagCatchOperator(), take(1)));
        if (!newData.succeeded || !newData.data || newData.data.length === 0) {
          patchState(store, {
            paymentTypes: INITIAL_HR_STATE.paymentTypes,
          });
          return;
        }

        patchState(store, {
          paymentTypes: { ...newData.data },
        });
      },

      getInsuraceTaxes: async (force_skip = false) => {
        const data = store.insuraceTaxes!();
        if (data) return;
        const newData = await firstValueFrom(httpClientHumanResources.getInsuranceTax().pipe(nswagCatchOperator(), take(1)));
        console.log('newData', newData);
        if (!newData.succeeded || !newData.data) {
          patchState(store, {
            insuraceTaxes: INITIAL_HR_STATE.insuraceTaxes,
          });
          return;
        }

        patchState(store, {
          insuraceTaxes: newData.data,
        });
      },
      clearHrCache: () => {
        patchState(store, INITIAL_HR_STATE);
      },
    }),
  ),
  withDevtools('HR'),
);
