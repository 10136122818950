import { createFeatureSelector, createSelector } from '@ngrx/store';
import { inventoryFeatureKey, InventoryState } from './inventory.state';
import { GetStateData } from '../../shared/interfaces/state/state';
import { Moment } from 'moment';
import { EXPIRE_API_SECONDS } from '../../shared/constants/api-priority.seconds';
import { configurationsFeatureKey, ConfigurationsState } from '../../configurations/store/configurations.state';

export const selectFeature = createFeatureSelector<InventoryState>(inventoryFeatureKey);

export const selectConfFeature = createFeatureSelector<ConfigurationsState>(configurationsFeatureKey);
export const selectSkipCache = (now: Moment) =>
  createSelector(selectConfFeature, config => GetStateData(config.skipCache, now, EXPIRE_API_SECONDS.SKIP_CACHE, false));
