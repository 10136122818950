import { withDevtools, withStorageSync } from '@angular-architects/ngrx-toolkit';
import { signalStore, withState, withComputed, withMethods, patchState } from '@ngrx/signals';

type DASHBOARD_STATE = {
  test: string | undefined;
  language: string | undefined;
  openedSidebar?: boolean;
};

const INITIAL_DASHBOARD_STATE: DASHBOARD_STATE = {
  test: undefined,
  language: undefined,
  openedSidebar: false,
};

export const DashboardStore = signalStore(
  { providedIn: 'root' },
  withState(INITIAL_DASHBOARD_STATE),
  withComputed(state => ({})),
  withMethods(store => ({
    loadLanguage: async (lang: string | undefined) => {
      const language = store.language();
      if (language === lang) return;
      patchState(store, {
        language: lang ? lang : language,
      });
    },
    loadSidebar: async (openedSidebar: boolean = false) => {
      const isSidebarOpened = store.openedSidebar!();
      if (isSidebarOpened !== undefined && openedSidebar === isSidebarOpened) return;
      patchState(store, {
        openedSidebar: openedSidebar,
      });
    },
  })),

  withStorageSync({
    key: 'dashboard',
    autoSync: true,
  }),
  withDevtools('users'),
);
