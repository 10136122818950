import { IStateData } from '../../shared/interfaces/state/state';
import {
  FixedAssetCategoryDto,
  GlAccountDto,
  ItemAccountingSchemeDto,
  ItemCategoryDto,
  ItemGroupDto,
  UnitOfMeasureDto,
} from '../../shared/nswag.api';

export const inventoryFeatureKey = 'inventory';

export const inventoryPersistKeys = [
  'cachedGlAccs',
  'cachedSchemes',
  'cachedUnits',
  'cachedParentCategory',
  'cachedGroups',
  'cachedCategories',
  'cachedAssetCategories',
  'cachedGlSchemes',
  'cachedAssetsGroups',
].map(value => `${inventoryFeatureKey}.${value}`);

export interface InventoryState {
  cachedSchemes: IStateData<ItemAccountingSchemeDto>[];
  cachedGroups: IStateData<ItemGroupDto>[];
  cachedAssetsGroups: IStateData<{ [key: string]: string | undefined }>[];
  cachedCategories: IStateData<ItemCategoryDto>[];
  cachedGlSchemes: IStateData<{ [key: string]: string | undefined }>[];
  cachedAssetCategories: IStateData<{ [key: string]: string | undefined }>[];
  cachedUnits: IStateData<UnitOfMeasureDto>[];
  cachedParentCategory: IStateData<FixedAssetCategoryDto>[];
  cachedGlAccs: IStateData<GlAccountDto>[];
}

export const initialInventoryState: InventoryState = {
  cachedSchemes: [],
  cachedUnits: [],
  cachedGlAccs: [],
  cachedParentCategory: [],
  cachedGlSchemes: [],
  cachedAssetCategories: [],
  cachedGroups: [],
  cachedCategories: [],
  cachedAssetsGroups: [],
};
