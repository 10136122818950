import { map, of, switchMap, take } from 'rxjs';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  ChangeTenantRequest,
  GoogleLoginRequest,
  HttpClientAuth,
  HttpClientPermission,
  LoginStep1Request,
  LoginStep2Request,
  UserResetPasswordTokenRequest,
} from '../../shared/nswag.api';
import { tap } from 'rxjs/operators';
import {
  authLoginSocialStep1Failure,
  authLoginSocialStep1Request,
  authLoginSocialStep1Success,
  authLoginStep1Failure,
  authLoginStep1Request,
  authLoginStep1Success,
  authLoginStep2Failure,
  authLoginStep2Request,
  authLoginStep2Success,
  authLogout,
  loadModulesFunctionsPermissions,
  loadModulesFunctionsPermissionsFailure,
  loadModulesFunctionsPermissionsSuccess,
  onRequestToken,
  onSwitchTenantFailure,
  onSwitchTenantRequest,
  onSwitchTenantSuccess,
} from './authorization.actions';
import { Store } from '@ngrx/store';
import { nswagCatchOperator } from '../../shared/operators/nswag-catch-operator';
import { Router } from '@angular/router';
import { SocialAuthService } from '@abacritt/angularx-social-login';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { HttpContext } from '@angular/common/http';
import { IGNORE_REDIS_CACHE } from '../../shared/interceptor/cache.interceptor';
import { SharedService } from 'src/app/shared/services/shared.service';

@Injectable()
export class AuthorizationEffects {
  loginStep1Request$ = createEffect(() =>
    this._actions.pipe(
      ofType(authLoginStep1Request),
      switchMap(({ contract, username, password, isGoogleLogin }) =>
        this.httpClientAuth
          .loginStep1(
            new LoginStep1Request({
              contractId: contract,
              username: username,
              passwordOrToken: password,
              isGoogleLogin: isGoogleLogin,
            }),
          )
          .pipe(
            nswagCatchOperator(),
            map(response =>
              response.succeeded && !!response.data
                ? authLoginStep1Success({
                    companyDtos: response.data.companies ?? [],
                    loggedUserName: response.data.name ?? '',
                  })
                : authLoginStep1Failure({
                    error: response.message ?? 'Unknown error',
                    contract: contract,
                    password: password,
                    username: username,
                  }),
            ),
          ),
      ),
    ),
  );

  loginStep1Failure$ = createEffect(
    () =>
      this._actions.pipe(
        ofType(authLoginStep1Failure),
        switchMap(({ error, contract, username, password }) => {
          if (error.includes('ERR:10252')) {
            this.store.dispatch(onRequestToken());
            return this.httpClientAuth
              .requestToken(
                new UserResetPasswordTokenRequest({
                  contract: contract,
                  userName: username,
                  password: password,
                }),
              )
              .pipe(
                nswagCatchOperator(),
                take(1),
                map(response => {
                  if (response.succeeded) {
                    this.toast.info(this.translate.instant('auth.change_first_password'));
                    this.router.navigate(['/auth/reset-password'], {
                      queryParams: { token: response.data },
                    });
                  } else this.toast.error(response.message);
                }),
              );
          } else {
            // this.toast.error(error);
            return of(null);
          }
        }),
      ),
    { dispatch: false },
  );

  loginSocialStep1Request$ = createEffect(() =>
    this._actions.pipe(
      ofType(authLoginSocialStep1Request),
      switchMap(({ token }) =>
        this.httpClientAuth.googleLoginStep1(new GoogleLoginRequest({ googleToken: token })).pipe(
          nswagCatchOperator(),
          map(response =>
            response.succeeded && !!response.data
              ? authLoginSocialStep1Success({
                  userContracts: response.data ?? [],
                })
              : authLoginSocialStep1Failure({
                  error: response.message ?? 'Unknown error',
                }),
          ),
        ),
      ),
    ),
  );
  loginSocialStep1Failure$ = createEffect(
    () =>
      this._actions.pipe(
        ofType(authLoginSocialStep1Failure),
        switchMap(({ error }) => {
          if (error.includes('ERR:10252')) {
            this.store.dispatch(onRequestToken());
            return this.httpClientAuth.requestToken(new UserResetPasswordTokenRequest({})).pipe(
              nswagCatchOperator(),
              take(1),
              map(response => {
                if (response.succeeded) {
                  this.router.navigate(['/auth/reset-password'], {
                    queryParams: { token: response.data },
                  });
                } else {
                  this.toast.error(response.message);
                }
              }),
            );
          } else {
            this.toast.error(error);
            return of(null);
          }
        }),
      ),
    { dispatch: false },
  );
  bc: BroadcastChannel = new BroadcastChannel('relogin_channel');
  loginStep2Request$ = createEffect(() => {
    return this._actions.pipe(
      ofType(authLoginStep2Request),
      tap(({ username }) => localStorage.setItem('attemptedUsername', username)),
      switchMap(({ company, username, password, isGoogleLogin }) =>
        this.httpClientAuth
          .loginStep2(
            new LoginStep2Request({
              companyDto: company,
              username: username,
              passwordOrToken: password,
              isGoogleLogin: isGoogleLogin,
            }),
          )
          .pipe(
            nswagCatchOperator(),
            take(1),
            map(response => {
              if (response.succeeded && !!response.data) {
                this.bc.postMessage('reload');
                return authLoginStep2Success({
                  company: company,
                  token: response.data.key as string,
                  refreshToken: response.data.value as string,
                });
              } else {
                return authLoginStep2Failure({
                  error: response.message ?? 'Unknown error',
                });
              }
            }),
          ),
      ),
    );
  });

  loginStep2Success$ = createEffect(
    () =>
      this._actions.pipe(
        ofType(authLoginStep2Success),
        tap(() => this.store.dispatch(loadModulesFunctionsPermissions({ skipCache: false }))),
      ),
    { dispatch: false },
  );

  loginStep2Failure$ = createEffect(
    () =>
      this._actions.pipe(
        ofType(authLoginStep2Failure),
        tap(() => this.store.dispatch(loadModulesFunctionsPermissionsFailure({ error: 'Login Error' }))),
      ),
    { dispatch: false },
  );

  logout$ = createEffect(
    () =>
      this._actions.pipe(
        ofType(authLogout),
        tap(user => {
          localStorage.removeItem('attemptedUsername');
          if (user) this.socialAuthService.signOut(true);
          this.store.dispatch(loadModulesFunctionsPermissionsFailure({ error: '' }));
          this.router.navigate(['/auth/login']);
        }),
      ),
    { dispatch: false },
  );

  loadMFP$ = createEffect(() =>
    this._actions.pipe(
      ofType(loadModulesFunctionsPermissions),
      map(value => (value.skipCache ? new HttpContext().set(IGNORE_REDIS_CACHE, true) : undefined)),
      switchMap(httpContext =>
        this.httpClientPermission.activePermissionsLoggedUser(httpContext).pipe(
          nswagCatchOperator(),
          map(response => {
            if (response.succeeded && !!response.data) {
              return loadModulesFunctionsPermissionsSuccess({
                modules: response.data.modules ?? [],
                functions: response.data.functions ?? [],
                permissions: response.data.permissions ?? [],
                configurations: response.data.companyConfigurations ?? [],
                isLoadingModulesFunctionsAndPermissions: false,
              });
            } else {
              return loadModulesFunctionsPermissionsFailure({
                error: response.message ?? 'Unknown error occurred',
              });
            }
          }),
        ),
      ),
    ),
  );

  loadMFPFailure$ = createEffect(
    () =>
      this._actions.pipe(
        ofType(loadModulesFunctionsPermissionsFailure),
        tap(({ error }) => {
          if (error !== '') this.toast.error(error);
          // if (error === 'invalid_token') this.store.dispatch(authLogout());
        }),
      ),
    { dispatch: false },
  );

  bcSwitchTenant: BroadcastChannel = new BroadcastChannel('company_switch_channel');
  switchTenantRequest$ = createEffect(() => {
    return this._actions.pipe(
      ofType(onSwitchTenantRequest),
      switchMap(({ company }) =>
        this.httpClientAuth
          .changeTenant(
            new ChangeTenantRequest({
              companyDto: company,
            }),
          )
          .pipe(
            nswagCatchOperator(),
            take(1),
            map(response => {
              if (response.succeeded && !!response.data) {
                this.bcSwitchTenant.postMessage('reload');
                this.sharedService.clearCacheFunction();
                return onSwitchTenantSuccess({
                  company: company,
                  token: response.data.key as string,
                  refreshToken: response.data.value as string,
                });
              } else {
                return onSwitchTenantFailure({
                  error: response.message ?? 'Unknown error',
                });
              }
            }),
          ),
      ),
    );
  });

  switchTenantRequestSuccess$ = createEffect(
    () =>
      this._actions.pipe(
        ofType(onSwitchTenantSuccess),
        tap(() => location.reload()),
      ),
    { dispatch: false },
  );

  switchTenantRequestFailure$ = createEffect(
    () =>
      this._actions.pipe(
        ofType(onSwitchTenantFailure),
        tap(() => this.store.dispatch(loadModulesFunctionsPermissionsFailure({ error: 'Login Error' }))),
      ),
    { dispatch: false },
  );
  constructor(
    private store: Store,
    private _actions: Actions,
    private httpClientAuth: HttpClientAuth,
    private httpClientPermission: HttpClientPermission,
    private router: Router,
    private toast: ToastrService,
    private socialAuthService: SocialAuthService,
    private translate: TranslateService,
    private sharedService: SharedService,
  ) {}
}
